<template>
    <a-select
        v-model="modelState"
        :loading="loading"
        :disabled="disabled"
        :allow-clear="allowClear"
        :placeholder="placeholder"
        @blur="e => $emit('blur', e)"
    >
        <a-select-option v-for="item in orgOptions" :key="item.id">
            {{ item.orgName }}
        </a-select-option>
    </a-select>
</template>
<script>
import { Select } from 'ant-design-vue'

import getOrgOptionsService from '@service/systemManage/organization/getOrgOptionsService'

export default {
    name: 'OrgSelect',
    components: {
        ASelect: Select,
        ASelectOption: Select.Option
    },
    props: {
        orgType: String,
        value: {
            type: String
        },
        placeholder: {
            type: String,
            default: '请选择'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        allowClear: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loading: false,
            orgOptions: []
        }
    },
    computed: {
        modelState: {
            get() {
                return this.value
            },
            set(v) {
                this.$emit('input', v)
                this.$emit('change', v)
            }
        }
    },
    watch: {
        orgType: {
            handler(orgType) {
                this.getOrgOptions(orgType)
            },
            immediate: true
        }
    },
    methods: {
        async getOrgOptions(orgType) {
            try {
                this.loading = true

                // eslint-disable-next-line
                this.orgOptions = await getOrgOptionsService({ orgType })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
