import { SAFETY_AUTH_URL } from '@config'

// 获取 `组织` 分页数据
export const GET_ORG_LIST = {
    url: `${SAFETY_AUTH_URL}/org-manager/page`,
    method: 'get',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 新增 `组织` 信息
export const APPEND_ORG = {
    url: `${SAFETY_AUTH_URL}/org-manager/save`,
    method: 'POST',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 修改 `组织` 信息
export const UPDATE_ORG = {
    url: `${SAFETY_AUTH_URL}/org-manager/update`,
    method: 'PUT',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}

// 获取 `组织` 下拉选项数据
export const GET_ORG_OPTIONS = {
    url: `${SAFETY_AUTH_URL}/org/list`,
    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    },
    timeout: 30000
}
